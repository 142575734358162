<section class="ui-container">
    <div class="ui-frame"><div class="ui-content">
<h1 class="font-title2">How to Use the Chef’s Coat Recipe App</h1>

<h2 class="font-title3">Creating a Meal Category</h2>
<div class="font-body">
    <p>Tap on the “+” at the bottom of the Categories side menu to add a meal category. Type the name of the category into the pop-up box and select Save. The category name will be found in the side menu on the left hand side of the page.</p>
    <p>A number will appear on the side of each category that you create to let you know just how many recipes can be found under that category.</p>
    <p>Categories can be deleted by holding down on the category name and selecting the delete option.</p>
    <p>The categories screen can be hidden for clearer view of recipe cards by tapping on the “menu” icon on the top left-hand side of the Categories side menu.</p>
</div>
<h2 class="font-title3">Inputting Recipes</h2>
<div class="font-body">
    <p>Add a recipe by tapping on the “Add Recipe” box. You will be taken to a new recipe card to input your recipe information.</p>
    <p>Recipes can be transferred into different categories if needed by holding down on the recipe name and dragging and dropping them into a new category on the left hand side of the screen.</p>
    <p>Recipes can be deleted by holding down on the recipe name and selecting the delete option.</p>
</div>
<h2 class="font-title3">Adding a Recipe Card</h2>
<div class="font-body">
    <p>Include your recipe title in the “Recipe Title” box.</p>
    <p>Tap on the recipe information box to include the author of the recipe, the cook time, serving size and oven temperature.</p>
    <p>Ingredients for the recipe can be added into the  “Ingredients” box.</p>
    <p>Instructions for the recipe can be added into the “Instructions” box.</p>
    <p>A picture can be included in the recipe by tapping on the camera icon and including an image from your photo roll.</p>
    <p>Once this information is provided, select “Done” on the right hand side of the screen.</p>
    <p>To edit the information in your recipe card, tap on the “Edit” tab on the right hand side of the screen.</p>
    <p>Ingredients and recipe instructions are found side by side on the iPad version and in tappable tabs on the iPhone version.</p>
    <p>To go back to the recipe menu, tap on the “menu” icon in the left hand side of the recipe card.</p>
</div>
<h2 class="font-title3">How to Use Timers</h2>
<div class="font-body">
    <p>Timers will be set up in a recipe wherever a time amount is provided in the instructions. Tap on the timer to start the countdown clock and the timer will notify you when that part of the cooking process is complete.</p>
    <p>More than one timer can be used in a recipe at once to monitor more than one  part of the meal prep (i.e. chicken must be cooked for 30 minutes in the oven, the green beans need to be steamed for 15 minutes on the stove, etc).</p>
    <p>Start, stop or reset the timers when necessary by tapping on the timer and selecting the necessary function from the menu.</p>
</div>


</div></div>
</section>
