<section class="ui-container">
    <div class="ui-frame"><div class="ui-content">
        <h1 class="font-title2">Terms and Conditions for davidcadinouche.com, chefscoat.org Websites and Mobile Applications</h1>

        <h2 class="font-title3">Effective April 10, 2021</h2>
        <div class="font-body">
            <p>davidcadinouche.com and chefscoat.org provide you with access to the apps under the terms and conditions embodied in this agreement (“Agreement”). Please read this Agreement carefully before beginning to access the items available on davidcadinouche.com and chefscoat.org . By purchasing an app created by davidcadinouche.com, and/or having access to chefscoat.org, you are acknowledging that you have agreed, and have given consent, to become a party to, and to be legally bound by, this Agreement. If you do not wish to follow, or to be bound by, the terms and conditions of this Agreement, you may not access or use the resources available on davidcadinouche.com and chefscoat.org . davidcadinouche.com and chefscoat.org reserves the right, from time to time, and potentially without notice to you, to make updates or changes to this Agreement. In your continued use of, and subscription to, davidcadinouche.com and chefscoat.org, you are accepting these changes that are made. The most current, and up-to-date version of this Agreement, which supplants all previous versions, can be found by clicking on the “Terms and Conditions” hyperlink in the footer of davidcadinouche.com and chefscoat.org, and within the Chef’s Coat app.</p>
            <p>davidcadinouche.com and chefscoat.org, by its sole discretion, can terminate your subscription to this app if your conduct is found to be illegal, out of step with, or in violation of, this Agreement in any way. You agree that your chefscoat.org login name and password are personal to you and non-transferable, and you agree that you will not allow any other person to use your login name or password to access chefscoat.org. You may download or print information from davidcadinouche.com and chefscoat.org, but you may not sell, make changes to, or distribute and/or publish the copies of these resources under your own name or organization.</p>
            <p>Visitors to chefscoat.org may submit suggestions, ideas, comments, questions, or other information through info@davidcadinouche.com, so long as the content that is submitted is not illegal, obscene, threatening, defamatory, invasive of privacy, violating copyright laws, or is otherwise objectionable. Submitted information must also not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of “spam.” When signing up for a subscription, you may not use a false e-mail address, pretend to be any other person or entity, or otherwise mislead as to the origin of payment, billing, or personal content.</p>
            <p>You may not use davidcadinouche.com and chefscoat.org  in any way that could disable, overburden, damage, or impair the davidcadinouche.com and chefscoat.org website or apps, that would be slanderous to davidcadinouche.com and chefscoat.org, or that would interfere with any other party’s use of the davidcadinouche.com and chefscoat.org sites. You agree never to transmit or attempt to transmit any detrimental data, software, or programs to the server hosting davidcadinouche.com and chefscoat.org. Any damage caused by you to the davidcadinouche.com and chefscoat.org sites, it’s apps, or other services may mean that you face both civil and criminal penalties. You may not access davidcadinouche.com and chefscoat.org through unauthorized means.</p>
            <p>MATERIALS AND CONTENT MADE AVAILABLE ON davidcadinouche.com AND chefscoat.org ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. davidcadinouche.com AND chefscoat.org  MAKES NO WARRANTIES OR REPRESENTATIONS, EXPRESS OR IMPLIED, CONCERNING THE QUALITY OR PERFORMANCE OF THE INFORMATION, MATERIALS, AND CONTENT THAT ARE INCLUDED ON THE SITES OR MADE AVAILABLE THROUGH davidcadinouche.com AND chefscoat.org. YOUR USE OF davidcadinouche.com AND chefscoat.org  IS AT YOUR OWN RISK. THERE IS ALWAYS THE POSSIBILITY OF ENCOUNTERING COMPUTER VIRUSES BY BEING ONLINE. davidcadinouche.com AND chefscoat.org  DOES NOT TAKE RESPONSIBILITY OR ASSUME LIABILITY FOR VIRUSES, AND SHALL NOT BE LIABLE FOR ANY DAMAGES CAUSED BY DELAY OR ERROR IN ACCESS OR TRANSMISSION, IMPROPER USE, OR ANY OTHER CAUSE OF ACTION RELATED TO davidcadinouche.com AND chefscoat.org.</p>
            <p>This Agreement constitutes the entire agreement between davidcadinouche.com and chefscoat.org and you with respect to the davidcadinouche.com and chefscoat.org websites and mobile apps. This Agreement shall be governed by and interpreted in accordance with the laws of Canada.</p>

        </div>



    </div></div>
</section>
