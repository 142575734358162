import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './UserInterface/Components/home/home.component';
import { PrivacyComponent } from './UserInterface/Components/privacy/privacy.component';
import { SupportComponent } from './UserInterface/Components/support/support.component';
import { TermsComponent } from './UserInterface/Components/terms/terms.component';

const routes: Routes = [
  {path: '', component: HomeComponent },
  {path: 'support', component: SupportComponent },
  {path: 'privacy', component: PrivacyComponent },
  {path: 'terms', component: TermsComponent },

  {path: 'apps/chefscoat/privacy', component: PrivacyComponent },
  {path: 'apps/chefscoat/support', component: SupportComponent },
  {path: 'apps/chefscoat/terms', component: TermsComponent }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
