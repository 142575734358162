<section class="ui-container pre-wrap-white-space">
    <div class="ui-frame"><div class="ui-content">
        <div class="hstack justify-start align-center">
            <div class="font-footnote">Copyright © 2021 David Cadinouche. All rights reserved. | </div>
            <div class="font-footnote">
                <a [routerLink]="['/','privacy']">Privacy Policy</a> |
                <a [routerLink]="['/','terms']">Terms of Use</a> |
                <a [routerLink]="['/','support']">Support</a>
            </div>
        </div>
   </div></div>
</section>
