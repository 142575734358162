import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './UserInterface/Components/home/home.component';
import { ImageComponent } from './UserInterface/Components/Views/image/image.component';
import { PrivacyComponent } from './UserInterface/Components/privacy/privacy.component';
import { SupportComponent } from './UserInterface/Components/support/support.component';
import { FooterComponent } from './UserInterface/Components/Global/footer/footer.component';
import { TermsComponent } from './UserInterface/Components/terms/terms.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ImageComponent,
    PrivacyComponent,
    SupportComponent,
    FooterComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
