
<section class="section ui-container section-header"><div class="ui-frame"><div class="ui-content">
    <div class="hstack align-center justify-center header">
        <app-image [src]="'david-cadinouche-logo'" class="View size-logo"></app-image>
        <div class="VStack View header-text">
            <div class="font-largeTitle">David Cadinouche</div>
            <div class="font-subtitle text-align-center">Digital Developer</div>
        </div>
    </div>
</div></div></section>


<section class="section ui-container section-apps"><div class="ui-frame"><div class="ui-content">
    <div class="hstack align-left justify-start">
        <!-- <app-image [src]="'david-cadinouche-logo'" class="View size-logo"></app-image> -->
        <div class="VStack View header-text">
            <div class="font-title2">iOS Apps</div>
            <div class="font-body">Swift, UIKit, SwiftUI, CoreData, CloudKit, StoreKit</div>
        </div>
    </div>
</div></div></section>

<section class="section ui-container section-info"><div class="ui-frame"><div class="ui-content">
    <div class="hstack justify-start align-center">

            <div class="hstack align-center justify-space-between hstack-chefs-coat">

                <div>
                    <div class="hstack hstack-chefs-coat-title">
                        <app-image [src]="'bible-reading-plan-web-logo'" class="View size-bible-reading-plan-web-sm-logo"></app-image>
                        <div class="font-title3">Bible Reading Plan</div>
                    </div>
                </div>
                
                <a href="https://apps.apple.com/ca/app/bible-reading-plan/id1233874464">
                    <div class="size-download-on-the-app-store">
                    <app-image [src]="'download-on-the-app-store'" class="View"></app-image>
                </div></a>
            </div>
            <div>
                <div class="font-subtitle">The Bible Reading App helps you create your own Bible study plans!</div>
                <div class="font-body">Set up a Bible study, input readings, read the ESV Bible, send study notes to group members, write out your own personal study notes, see group study questions and, now with the Interact monthly subscription, get involved in group discussion forums.</div>

                <a target="_blank" href="https://alivetoworship.org">
                    <div class="font-footnote app-info-link">alivetoworship.org</div>
                </a>
            </div>

    </div>
</div></div></section>

<section class="section ui-container section-chefscoat"><div class="ui-frame"><div class="ui-content">
    <div class="hstack justify-start align-center">

            <div class="hstack align-center justify-space-between hstack-chefs-coat">

                <div>
                    <div class="hstack hstack-chefs-coat-title">
                        <app-image [src]="'chefscoat-web-logo'" class="View size-sm-logo"></app-image>
                        <div class="font-title3">Chef's Coat</div>
                    </div>
                </div>
                <a href="https://apps.apple.com/us/app/chefs-coat/id1554678162">
                    <div class="size-download-on-the-app-store">
                    <app-image [src]="'download-on-the-app-store'" class="View"></app-image>
                </div></a>
            </div>
            <div>
                <div class="font-body">Chef's Coat is a recipe app designed to be used by the home chef or baker. Keep all of your recipes in one place, transfer any loose paper/hard copy recipes that you have received to a digital format, have access on your iPad or iPhone to the culinary creations in your recipe boxes by including them as Chef's Coat recipe cards, create your own cookbook by grouping recipes by course or food type using your own meal categories, display photos of your food creations, or use multiple timers at once in recipes to keep track of different steps in the cooking process.</div>
                <a target="_blank" href="https://chefscoat.org">
                    <div class="font-footnote app-info-link">chefscoat.org</div>
                </a>
            </div>

    </div>
</div></div></section>
