<section class="ui-container">
    <div class="ui-frame"><div class="ui-content">
        <h1 class="font-title2">Privacy Policy for davidcadinouche.com, chefscoat.org Websites and Mobile Applications</h1>


        <h2 class="font-title3">Effective April 10, 2021</h2>
        <div class="font-body">
            <p>davidcadinouche.com values your privacy and wants to protect your personal information through strict adherence to security measures pertaining to its’ collection and use. This privacy policy provides information about how we collect and use information obtained from you on any davidcadinouche.com mobile and web apps (i.e. Chef’s Coat recipe app) that we have developed and sell in the App Store.</p>
            <p>Please note that we are not responsible for any content or privacy practices on sites not operated by davidcadinouche.com that link to our sites or apps. Also, by using the davidcadinouche.com websites or our mobile and web applications you are consenting to your personal data being used according to the terms of this privacy policy. Please keep up to date on reviewing this policy to make sure that you are apprised of any updates or changes made due to additional mobile and web app phases being developed. By continuing to use our site or mobile and web applications following updates or revisions made to the policy, this will be considered an acceptance of what has been laid out in the policy terms.</p>
        </div>

        <h2 class="font-title3">Children’s Privacy Policy</h2>
        <div class="font-body">
            <p>The davidcadinouche.com mobile and web applications developed under this organization are not directed to children under the age of 13. Access to the davidcadinouche.com mobile applications must be purchased through the Apple App Store. As per the Apple Store policy, children under the age of 13 must have parental permission to use an AppleID, and requisite credit card number, to purchase the mobile apps created by davidcadinouche.com.</p>

        </div>

        <h2 class="font-title3">How Personal Information is Used</h2>
        <div class="font-body">
            <p>a) We do not collect personal information from other third-party sources, nor do we share your information with sources outside of our organization.</p>
            <p>b) Use of Cookies: The davidcadinouche.com website uses cookies to enable the efficient navigation of our site, to keep on top of how often our site is frequented, to understand the usage practices of consumers to our site, and to make the user experience of our site more inviting. Cookies are a trail of text file information left from your having accessed a site. This information is stored by your Internet browser on the hard drive of your computer. To shut off this default function, you will need to make this change in the “settings” of your computer, but, by doing so, it may inhibit you from accessing particular sections of our websites.</p>
            <p>The davidcadinouche.com website, and its’ mobile applications, do not store personal information in any way. The only information that may be logged, as with most other sites, is the Internet URL or “IP address” of the computer from which you access the site.</p>

        </div>



        <h2 class="font-title3">Data Use and Sharing</h2>
        <div class="font-body">
        <p>The basic functionality of the davidcadinouche.com mobile Chef’s Coat application is to be used by the home chef and baker to keep all the user’s recipes in one place, transfer any loose paper/hard copy recipes that have been received to a digital format, have access across the user’s iPad or iPhone to the user’s culinary creations by including them as Chef’s Coat recipe cards, and to create a user’s individual cookbook by grouping recipes by course or food type using a user’s own personalized meal categories. There is also the ability to share recipes between monthly subscribers. A subscriber can share a recipe, for instance, with another individual from their contact list, whether through messaging or email, and, when that individual taps on the “share”, the recipe that was sent can be viewed. As per the Apple Developer’s Privacy Policy, we don’t retain any of this information (email addresses, phone numbers, or first and last names) on our servers. All of the information is solely found on the sharers device as access is needed to the Apple CloudKit services, and the individual’s personal Cloud, to share with other individuals.</p>
        <p>The davidcadinouche.com website or mobile applications do not ask for any personal information (i.e. through passwords, credit card information, usernames, etc) from its’ users.</p>
        </div>
        <h2 class="font-title3">Accountability</h2>
        <div class="font-body">
        <p>To contact davidcadinouche.com, please send emails to this account: info@davidcadinouche.com</p>
        </div>


</div></div>
</section>
